<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            head-row-variant="secondary"
            :items="slugs"
            :fields="fields"
            @row-clicked="clicked"
            @row-dblclicked="editForm"
            @row-contextmenu="handleContextMenuEvent"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(created_at)="data">
              {{ moment(data.item.created_at).format("DD.MM.YYYY HH:mm") }}
            </template>
            <template #cell(updated_at)="data">
              {{ moment(data.item.updated_at).format("DD.MM.YYYY HH:mm") }}
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link class="d-flex align-items-center" @click="deleteSlug">
                <feather-icon icon="DeleteIcon" />
                <span class="ml-75">Удалить</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import moment from "moment";
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["slugs"],
  components: {
    VueContext,
    ToastificationContent,
  },
  data() {
    return {
      id: null,
      fields: [
        { key: "checkbox", label: "" },
        { key: "id", label: "ID", sortable: true },
        { key: "slag", label: "Название", sortable: true },
        { key: "created_by", label: "Создал", sortable: true },
        { key: "updated_by", label: "Обновил", sortable: true },
        { key: "created_at", label: "Создан", sortable: true },
        { key: "updated_at", label: "Обновлен", sortable: true },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    moment,
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;

      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();

      cellSelect();
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item.id;
    },
    deleteSlug() {
      this.$http
        .delete(`slug/${this.id}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Slug удален!",
              icon: "XIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    editForm(item) {
      this.$emit("editForm", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
