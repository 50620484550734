import { render, staticRenderFns } from "./tableSlug.vue?vue&type=template&id=ce511486&scoped=true&"
import script from "./tableSlug.vue?vue&type=script&lang=js&"
export * from "./tableSlug.vue?vue&type=script&lang=js&"
import style0 from "./tableSlug.vue?vue&type=style&index=0&id=ce511486&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce511486",
  null
  
)

export default component.exports